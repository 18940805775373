import { Box, useMediaQuery, useStyleConfig } from "@chakra-ui/react";

import { H2 } from "@components/atoms/Text/H2";
import { ContactDetails } from "@components/organisms";
import { breakpoints } from "@styles/constants";

export const SeamlessLending = () => {
  const h2Styles = useStyleConfig("h2");
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  return (
    <Box
      w="full"
      textAlign="left"
      padding={!isMobile ? "3.75rem" : "1.5rem 0.93rem"}
      backgroundColor="secondary"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", null, null, "row"]}
      >
        <Box w={["100%"]}>
          <H2 __css={h2Styles} textTransform="uppercase">
            Seamless Lending and Hiring
          </H2>
          <ContactDetails />
        </Box>
      </Box>
    </Box>
  );
};
